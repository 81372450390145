import React,  { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import Img from "gatsby-image";
import Layout from "../components/layout"
import SEO from "../components/seo";


class SinglePage extends Component {
    render() {
        const page = this.props.data.wordpressPage

        return (
<Layout>
    <SEO
        title={page.yoast_meta.yoast_wpseo_title}
        description={page.yoast_meta.yoast_wpseo_metadesc}

    />
            <div
            >                     <Img
                alt={page.featured_media.alt_text}
                fluid={page.featured_media.localFile.childImageSharp.fluid}


            />
                <h2
                    dangerouslySetInnerHTML={{ __html: page.title }}

                />
                {parse(page.content)}
            </div>

</Layout>
        )
    }
}

SinglePage.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default SinglePage

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      
      featured_media {
        source_url
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 804) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`



